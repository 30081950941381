















































































import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import { ActionMethod } from 'vuex';
import { Action, Getter } from 'vuex-class';
import BaseButton from '@improve/common-components/src/components/widgets/BaseButton.vue';
import TicketStats from '@improve/common-utils/src/types/TicketStats';
import TicketSearchParams from '@improve/common-utils/src/types/TicketSearchParams';
import Ticket from '@improve/common-utils/src/model/Ticket';
import ImproveTicketStatus from '@improve/common-utils/src/types/ImproveTicketStatus';
import User from '@improve/common-utils/src/model/User';
import BaseTicketCard from '../components/ticket/BaseTicketCard.vue';

@Component({
  name: 'Dashboard',
  components: {
    BaseButton,
    BaseTicketCard
  }
})
export default class Dashboard extends Vue {
  @Getter ticketStats!: TicketStats;

  @Getter foundTickets?: Array<Ticket>

  @Getter currentUser!: User;

  @Action getTicketStats!: ActionMethod;

  @Action getAllTickets!: ActionMethod;

  @Action viewAllTickets!: ActionMethod;

  tickets: Array<Ticket> = [];

  async created(): Promise<void> {
    const searchBy = new TicketSearchParams();
    searchBy.size = 10;
    searchBy.status = this.getSearchByStatus();
    await this.getTicketStats();
    this.tickets = await this.getAllTickets(searchBy);
  }

  createImprove(): void {
    this.$router.push({ name: 'CreateTicket' });
  }

  getSearchByStatus(): Array<ImproveTicketStatus> {
    return [
      ImproveTicketStatus.READY_TO_REVIEW,
      ImproveTicketStatus.IN_REVIEW,
      ImproveTicketStatus.WAITING_FOR_IMPLEMENTATION,
      ImproveTicketStatus.IMPLEMENTED
    ];
  }

  async viewAllClicked(): Promise<void> {
    const searchBy = new TicketSearchParams();
    searchBy.status = this.getSearchByStatus();
    await this.viewAllTickets(searchBy);
    this.$router.push({ name: 'SearchAllResults', query: { search: searchBy.status, viewAllTicketsFromDash: 'true' } });
  }
}
